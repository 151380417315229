import React from 'react';
import { useLocation } from 'react-router-dom';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';


import * as ROUTES from '../../constants/routes';


const Navigation = () => {
  const location = useLocation();

  return (

<Navbar className="px-5" bg="light" expand="lg" fixed="top">
  <Navbar.Brand href={ROUTES.HOME}>Marc Matvienko</Navbar.Brand>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="ml-auto pl-3">
      <Nav.Link className={`mx-3 ${location.pathname === ROUTES.HOME ? 'active' : ''}`} href={ROUTES.HOME}>Home</Nav.Link>
      <Nav.Link className={`mx-3 ${location.pathname === ROUTES.EDUCATION ? 'active' : ''}`} href={ROUTES.EDUCATION}>Education</Nav.Link>
      <Nav.Link className={`mx-3 ${location.pathname === ROUTES.EXPERIENCE ? 'active' : ''}`} href={ROUTES.EXPERIENCE}>Experience</Nav.Link>
      <Nav.Link className={`mx-3 ${location.pathname === ROUTES.RESEARCH ? 'active' : ''}`} href={ROUTES.RESEARCH}>Research</Nav.Link>
      <Nav.Link className="mx-3" href={ROUTES.RESUME}>Resume</Nav.Link>
    </Nav>
    {/* <Button className="mx-3" variant="dark" href={ROUTES.SIGN_IN}>Sign In</Button> */}
  </Navbar.Collapse>
</Navbar>
)};

export default Navigation;
