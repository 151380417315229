export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';

// export const LANDING = '/';
export const LANDING = '/landing';
export const HOME = '/';
// export const HOME = '/home';

export const EXPERIENCE = '/experience';
export const EDUCATION = '/education';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';
export const EDITOR = '/editor';
export const RESUME = '/resume';
export const POSTS = '/posts';
export const RESEARCH = '/research';