import React from 'react';
import PropTypes from 'prop-types';
import "./image_box.css";

const ImageRight = ({ imageSrc, titleText, bodyText }) => {
    return (
      <div className="circle-container">

        <div className="circle-text">
          <h1>{titleText}</h1>
          <p>{bodyText}</p>
        </div>
        <div className="padding-div"></div>
        <div className="circle-image">
          <img src={imageSrc} alt="Circle Image" />
        </div>
      </div>
    );
  }
ImageRight.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  titleText: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
};

export default ImageRight;
