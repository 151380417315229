import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
//import 'katex/dist/katex.min.css';
//import { InlineMath, BlockMath } from 'react-katex';
import { MathJax, MathJaxContext } from "better-react-mathjax";

const ResearchPage = () => {
    const [htmlContent, setHtmlContent] = useState('');
    const { fileName } = useParams();

    useEffect(() => {
      //MathJax.Hub.Queue(["Typeset",MathJax.Hub,"output"]);
      fetch(`/research/${fileName}.html`)
        .then(response => response.text())
        .then(data => {
          setHtmlContent(data);
        });
    }, [fileName]);
  
    if (!htmlContent) return <p>Loading...</p>;

    return (
      <Container fluid>
      <Row className="justify-content-center home-page">
        <Col xl= {8} lg={10} md={10} sm={10}>
          <MathJaxContext config={{
            tex: {
              inlineMath: [['$', '$'], ['\\(', '\\)']],
              displayMath: [['$$', '$$'], ['\\(', '\\)']]
            },
            svg: {
              fontCache: 'global'
            }
          }}>
            <div id="research" dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </MathJaxContext>
        </Col>
      </Row>
    </Container>
    );
}

export default ResearchPage;
