import React from 'react';

const EducationBlock = ({ education_block }) => {
  const separator = ", ";
  const degrees = education_block.degrees.map((degree) => {
    const joinedItems = degree.relevantCourseWork.join(separator);
    return (
      <div key={degree.degree} className="degree-block">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h4 style={{ margin: 0 }}>{degree.degree}</h4>
            <p>{degree.startDate} - {degree.endDate}</p>
        </div>
        <div>
          {joinedItems}
        </div>
      </div>
    );
  });
  
  return (
    <div className='education-block'>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2 style={{ color: "#BF5700"}}>{education_block.university}</h2>
        {/* <p style={{float: 'right'}}>{education_block.location}</p> */}
      </div>
      {degrees}
    </div>
  );
};

export default EducationBlock;