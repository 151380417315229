import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './education.css';
import resume from '../../assets/resume.json';
import { Helmet } from 'react-helmet';
import EducationBlock from './education';

const Education = () => {
  return (
    <Container fluid>
      <Helmet>
        <title>Education</title>
      </Helmet>
      <Row className="justify-content-center home-page">
        <Col xl= {6} lg={8} md={10} sm={10}>
            <EducationBlock
                education_block={resume.education}
            />
            {/* <EducationBlock
                education_block={resume.education[1]}
            /> */}
        </Col>
      </Row>
    </Container>
  );
}

export default Education;