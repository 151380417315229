import React from 'react';

class Resume extends React.Component {

  render = () => {
    return(
      <div>
        <h1>The Resume Page</h1>
        <p>More is coming. Under construction rn.</p>
      </div>
      
    );
  }
}

export default Resume;