import { Container, Row, Col } from 'react-bootstrap';
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import "./research.css"
import content from "../../assets/content.json"
const Research = () => {
  const [selectedPage, setSelectedPage] = useState('');

  const handlePageClick = (name) => {
    setSelectedPage(name);
  };

  if (selectedPage) {
    return <Navigate to={`/research/${selectedPage}`} replace/>;
  }

  return (
    <Container fluid>
    <Row className="justify-content-center home-page">
      <Col xl= {6} lg={8} md={10} sm={10}>
        {content.pages.map((page) => (
          <div  key={page.name}>
            <h2
              className="clickable-div"
              onClick={() => handlePageClick(page.name)}
            >{page.title}</h2>
            <p dangerouslySetInnerHTML={{ __html: page.text }}></p>
          </div>
        ))}
      </Col>
    </Row>
  </Container>
  
  );
};

export default Research;