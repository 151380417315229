import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ImageLeft from './image_left';
import ImageRight from './image_right';
import './HomePage.css';

const HomePage = () => {
  /** 
   * The imageSrc has to be an object in the ./public/ folder
   */
  return (
    <Container fluid>
      <Row className="justify-content-center home-page">
        <Col xl= {6} lg={8} md={10} sm={10}>
          <ImageLeft
            imageSrc={'profile_pic.jpeg'}
            titleText="Howdy! I'm Marc"
            bodyText="My most recent passion outside of work is finance. I try to soak up everything from market microstructure, to bonds/futures pricing & mechanics, and macroeconomics. Currently I focus on developing alphas for a mid-frequency system I have paper trading (for now) - checkout the research page! I keep myself sane by swimming, running, lifting, climbing, or biking. "
            isCircular={true}
          />
          <ImageRight
            imageSrc={'Amazon_icon.svg'}
            titleText="I currently work at Amazon"
            bodyText="where we're developing a system that detects damage with computer vision. My main focus is collecting data from world wide systems, annotating the data at scale, and accelerating the development of new models. I designed and owned the annotation management service, regionalized our data collection service, and developed the science data warehouse."
            isCircular={false}
          />        
          <ImageLeft
            imageSrc={'ut_seal.svg'}
            titleText="I studied at UT Austin"
            bodyText="I dabbled in physics, math, and business, but mainly focused on computer science with a focus on machine learning and AI. I spent 3-4 semesters researching how to improve domain specific word embeddings with small data sets. Also developed state of the art object detection models to be used in agent control."
            isCircular={false}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default HomePage;