import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import JsonExperiences  from './json_experiences';
import experiences_json from '../../assets/resume.json';
import './experience.css';
import { Helmet } from 'react-helmet';

const Experience = () => {
  return (
    <Container fluid>
    <Helmet>
        <title>Work Experience</title>
      </Helmet>
      <Row className="justify-content-center home-page">
        <Col xl= {6} lg={8} md={10} sm={10}>
          <JsonExperiences
            experiences={experiences_json.experiences}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default Experience;