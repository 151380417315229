import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Navigation from '../Navigation';
import HomePage from '../Home';
import Resume from '../Resume';
import Experience from '../Experience';
import Education from '../Education';
import Research from '../Research';
import ResearchPage from '../Research/research_page';

import * as ROUTES from '../../constants/routes';

const App = () => (
  <Router>

    <div>
      <Navigation />
      <div style={{"marginTop": "4em"}}>
        <Routes>
          <Route exact path={ROUTES.EDUCATION} element={<Education />} />
          <Route exact path={ROUTES.EXPERIENCE} element={<Experience />} />
          <Route exact path={ROUTES.RESEARCH} element={<Research />} />
          <Route exact path={ROUTES.RESUME} element={<Resume />} />

          <Route exact path={ROUTES.HOME} element={<HomePage/>} />
          <Route path="/research/:fileName" element={<ResearchPage />} />
        </Routes>
      </div>
    </div>
  </Router>
);

export default App;
