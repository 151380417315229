import React from 'react';


const JsonExperiences = ({ experiences }) => {
  return (
    <div>
      {experiences.map((exp) => (
        <div key={exp.id}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h3 style={{ margin: 0 }}>{exp.company}</h3>
            <p style={{ margin: 0, textAlign: 'right' }}>{exp.location}</p>
          </div>
          <p>{exp.position} <p style={{float: 'right'}}>{exp.startDate} - {exp.endDate}</p></p>
          
          <ul>
            {exp.highlights.map((desc, index) => (
              <li key={index}>{desc}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default JsonExperiences;